import get from 'lodash/get';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Checkbox,
  Badge,
  Tooltip,
  useToast
} from '@chakra-ui/react';

import HelperColumnCell from './HelperColumnCell';
import { openFrame } from 'src/store/actions/frameActions';
import type { State } from 'src/types/state';
import type { HelperColumnConfig, WidgetAlertConfig, WidgetStatusConfig } from 'src/types/Config';

export default function WidgetStatus({
  status,
  tickets,
  helperColumns,
  alertsEnabled,
  entity
}: {
  status: WidgetStatusConfig;
  tickets: { [key: string]: any }[];
  helperColumns?: HelperColumnConfig[];
  alertsEnabled: boolean;
  entity: Record<string, unknown>;
}) {
  const toast = useToast();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const alerts = useSelector((state: State) => state.viewConfig?.alerts);
  const [showUnderCheckbox, setShowUnderCheckbox] = useState(false);

  const handleAlert = (ticket: { [key: string]: unknown }, alert: WidgetAlertConfig) => {
    let remaining: number;
    switch (alert.valueType) {
      case 'time': {
        remaining = calculateRemainingToMaintenance(get(ticket, alert.fieldPath) as number, moment().unix(), alert);
        break;
      }

      case 'number': {
        if (alert.entityPath !== undefined) {
          remaining = calculateRemainingToMaintenance(
            get(ticket, alert.fieldPath) as number,
            parseInt(get(entity, alert.entityPath) as string),
            alert
          );
        } else {
          showMissconfigurationToast();
        }
        break;
      }

      default: {
        showMissconfigurationToast();
        return <></>;
      }
    }

    const isBelowTreshold = checkBelowTreshold(remaining!, alert);
    return renderAlert(remaining!, alert, isBelowTreshold);
  };

  const calculateRemainingToMaintenance = (maintenanceValue: number, latestValue: number, alert: WidgetAlertConfig) => {
    const interval = typeof alert.multiplier != 'undefined' ? alert.interval * alert.multiplier : alert.interval;
    let result = interval - (latestValue - maintenanceValue);

    if (alert.multiplier) {
      result = Math.round(result / alert.multiplier);
    }
    return result;
  };

  const checkBelowTreshold = (remaining: number, alert: WidgetAlertConfig) => {
    let threshold = alert.alertThreshold;
    if (alert.multiplier) {
      threshold *= alert.multiplier;
    }

    return remaining <= threshold;
  };

  const renderAlert = (remaining: number, alert: WidgetAlertConfig, isBelowTreshold: boolean) => {
    return (
      <Tooltip label={`${alert.interval} ${alert.fullUnit}`}>
        <Badge variant="subtle" colorScheme={isBelowTreshold ? 'red' : 'green'} mr={1}>
          {`${remaining} ${alert.unit}`}
        </Badge>
      </Tooltip>
    );
  };

  const showMissconfigurationToast = () =>
    toast({
      title: 'Error occured',
      description: t('toasts.alert_config_error'),
      status: 'error',
      duration: 4000,
      isClosable: true
    });

  const render = () => (
    <Box mb={1} style={{ width: '100%' }}>
      {status.title && (
        <Text fontSize="lg">
          {status.title} ({tickets.length})
        </Text>
      )}

      {tickets.length > 0 && (
        <TableContainer style={{ border: '1px solid #e2e8f0', borderRadius: '12px', marginTop: '10px', width: '100%' }}>
          <Table size="md" variant="striped">
            <Thead>
              <Tr>
                <Th>Created</Th>

                {helperColumns?.map((helperColumn) => (
                  <Th key={helperColumn.columnName}>{helperColumn.columnName}</Th>
                ))}

                <Th>Title</Th>
                <Th>Author</Th>

                {alertsEnabled && alerts?.length && <Td>{t('labels.no_provider_enabled')}</Td>}
              </Tr>
            </Thead>

            <Tbody>
              {tickets.map((ticket) => (
                <Tr
                  key={ticket.id}
                  onClick={() => dispatch(openFrame({ contentId: ticket.id }))}
                  style={{ cursor: 'pointer' }}
                >
                  <Td>{moment(ticket.created * 1000).format('DD.MM.YYYY')}</Td>

                  {helperColumns?.map((helperColumn) => (
                    <HelperColumnCell key={helperColumn.columnName} helperColumnConfig={helperColumn} ticket={ticket} />
                  ))}

                  <Td>
                    <Text isTruncated>{ticket.title}</Text>
                  </Td>
                  <Td>
                    {ticket.createdUserProfile.firstName} {ticket.createdUserProfile.lastName}
                  </Td>

                  {alertsEnabled && alerts?.length && (
                    <Td>
                      {alerts
                        .filter((alert) =>
                          ticket.tags.find((tag: string) => alert.tagIds.includes(parseInt(tag.substring(3))))
                        )
                        .map((alert) => handleAlert(ticket, alert.config))}
                    </Td>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );

  if (status.hideUnderCheckbox) {
    return (
      <>
        <Checkbox checked={showUnderCheckbox} onChange={() => setShowUnderCheckbox(!showUnderCheckbox)}>
          {status.hideCheckboxText}
        </Checkbox>

        {showUnderCheckbox && render()}
      </>
    );
  }

  return render();
}
