import React from 'react';
import {
  Heading,
  Skeleton,
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton
} from '@chakra-ui/react';
import sortBy from 'lodash/sortBy';

import NewTicketForm from 'src/NewTicketForm';
import CustomerEntityInfo from 'src/CustomerEntityInfo';
import TicketList from 'src/TicketList';
import BackendApi from 'src/api/BackendApi';
import FeatureFlags from 'src/api/FeatureFlags';
import { getUrlSearchParam } from 'src/utils';
import type Ticket from 'src/types/Ticket';
import type { TicketTypeEntity } from 'src/CustomerEntityInfo';

interface HashIdentityProps {}

interface HashIdentityState {
  tickets: Ticket[];
  isLoading: boolean;
  users: { [key: string]: any }[];
  customerData: Record<string, string>;
  ticketTypeEntity: TicketTypeEntity[];
  contentLoaded: boolean;
  noHash: boolean;
  error: boolean;
  selectedTicket: string | null;
}

export default class HashIdentity extends React.Component<HashIdentityProps, HashIdentityState> {
  constructor(props: HashIdentityProps) {
    super(props);

    this.state = {
      tickets: [],
      isLoading: false,
      users: [],
      customerData: {},
      ticketTypeEntity: [],
      contentLoaded: false,
      noHash: false,
      error: false,
      selectedTicket: null
    };
  }

  async componentDidMount() {
    const isEID = FeatureFlags.isFlagOn('ENABLE_PORTAL_EID_AUTH');

    if (!isEID) {
      const query = window.location.search;
      const urlParams = new URLSearchParams(query);
      const hash = getUrlSearchParam('hash');
      const ticketId = urlParams.get('ticketId');
      if (!hash) {
        this.setState({ noHash: true });
      } else {
        this.handleEntitiesFetch(hash, ticketId);
      }
    } else {
      this.handleEntitiesFetch();
    }
  }

  private handleEntitiesFetch = (hash?: string, ticketId?: string | null) => {
    this.setState({ isLoading: true, noHash: false }, async () => {
      try {
        const ticketResponse = await BackendApi.getTicketByHash(hash);
        const entityResponse = ticketResponse.tickets[0]?.taskType
          ? await BackendApi.getEntityByHash({
              hash,
              ticketType: ticketResponse.tickets[0]?.taskType
            })
          : {};

        this.setState(
          {
            tickets: sortBy(ticketResponse.tickets, (x) => x.id).reverse(),
            ticketTypeEntity: ticketResponse.entity,
            customerData: entityResponse,
            selectedTicket: ticketId || null
          },
          () => {
            this.setState(
              {
                isLoading: false,
                contentLoaded: true,
                noHash: false
              },
              () => {
                if (this.state.selectedTicket) {
                  const elmnt = document.getElementById(this.state.selectedTicket);
                  elmnt?.scrollIntoView();
                }
              }
            );
          }
        );
      } catch (error) {
        console.error(error);
        this.setState({ error: true });
      }
    });
  };

  private fetchTickets = async () => {
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    const hash = urlParams.get('hash');
    const ticketResponse = await BackendApi.getTicketByHash(hash as string);
    this.setState({ tickets: ticketResponse.tickets });
  };

  render() {
    const { contentLoaded, noHash, customerData, tickets, ticketTypeEntity, error, selectedTicket } = this.state;

    return (
      <>
        {error && (
          <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Alert status="error" borderRadius={8}>
              <AlertIcon />
              <AlertTitle mr={2}> On tapahtunut virhe. </AlertTitle>
              <AlertDescription>Päivitä sivu tai ota yhteyttä asiakaspalveluun.</AlertDescription>
              <CloseButton position="absolute" right="8px" top="8px" />
            </Alert>
          </div>
        )}

        {noHash === false && !error && (
          <div className="App">
            <div className="formContainer">
              <Heading className="mainHeading" textAlign="center" as="h2">
                Asiakasportaali
              </Heading>

              <NewTicketForm fetchTickets={this.fetchTickets} />

              {!this.state.contentLoaded ? (
                <Stack className="customerPortalSkeletonStack" spacing="3" justify="center">
                  <Skeleton height="40px" isLoaded={contentLoaded} />
                  <Skeleton height="40px" isLoaded={contentLoaded} />
                  <Skeleton height="40px" isLoaded={contentLoaded} />
                </Stack>
              ) : (
                <>
                  <CustomerEntityInfo customerData={customerData} ticketTypeEntity={ticketTypeEntity} />

                  <TicketList tickets={tickets} selectedTicket={selectedTicket} fetchTickets={this.fetchTickets} />
                </>
              )}
            </div>
          </div>
        )}

        {noHash && (
          <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Alert status="error" borderRadius={8}>
              <AlertIcon />
              <AlertTitle mr={2}>Puuttuva tunniste </AlertTitle>
              <AlertDescription>Tarkista sähköpostisi linkki.</AlertDescription>
              <CloseButton position="absolute" right="8px" top="8px" />
            </Alert>
          </div>
        )}
      </>
    );
  }
}
