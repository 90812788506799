import React, { useState, useEffect } from 'react';
import iziToast from 'izitoast';
import { Heading, FormControl, FormLabel, Input, Textarea, Button, useToast } from '@chakra-ui/react';
import { PlusSquareIcon } from '@chakra-ui/icons';

import BackendApi from './api/BackendApi';

interface NewTicketFormProps {
  fetchTickets: () => Promise<void>;
}

export const NewTicketForm = (props: NewTicketFormProps) => {
  const [subject, setSubject] = useState<string>();
  const [content, setContent] = useState<string>();
  const [files, setFiles] = useState<FileList>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (isSubmitting) {
      const query = window.location.search;
      const urlParams = new URLSearchParams(query);
      const hash = urlParams.get('hash');
      const data = new FormData();

      if (files) {
        for (var i = 0; i < files?.length; i++) {
          const file = files[i];
          data.append('files[' + i + ']', file);
        }
      }

      if (subject) data.append('subject', subject);
      if (content) data.append('content', content);

      BackendApi.createNewTicket(hash!, data)
        .then((response) => {
          toast({
            title: 'Viesti lähetetty.',
            description: 'Viesti lähetetty onnistuneesti',
            status: 'success',
            duration: 1000,
            isClosable: true
          });
          if (response.status === 200 || response.status === 201) {
            iziToast.success({
              title: 'Success',
              message: 'New ticket created'
            });
            props.fetchTickets();
          }
        })
        .catch((error) => {
          console.error(error);
          toast({
            title: 'Error',
            description: 'Failed to submit a message.',
            status: 'error',
            duration: 1000,
            isClosable: true
          });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  }, [isSubmitting]);

  const onChange = (value: FileList | string | null, field: string) => {
    if (field === 'content') {
      setContent(value as string);
    }
    if (field === 'subject') {
      setSubject(value as string);
    }
    if (field === 'files') {
      setFiles(value as FileList);
    }
  };

  const onSubmit = () => {
    setIsSubmitting(true);
  };

  return (
    <div className="portalNewTicketForm">
      <Heading size="md">
        <PlusSquareIcon />
        <span className="portalNewTicketHeading">Uusi viesti</span>
      </Heading>
      <form>
        <FormControl className="portalNewTicketInputGroup">
          <FormLabel htmlFor="subject">Otsikko</FormLabel>
          <Input
            className="portalNewTicketInput"
            type="text"
            id="subject"
            size="md"
            height="40px"
            placeholder=" Aihe"
            onChange={(e) => onChange(e.target.value, 'subject')}
          />
        </FormControl>
        <FormControl className="portalNewTicketInputGroup">
          <FormLabel htmlFor="content">Viesti</FormLabel>
          <Textarea
            className="portalNewTicketInput "
            id="content"
            placeholder=" Sisältö"
            onChange={(e) => onChange(e.target.value, 'content')}
          />
        </FormControl>
        <FormControl className="portalNewTicketInputGroup">
          <label htmlFor="fileupload"> Valitse tiedostoja </label>

          <div>
            <input id="fileupload" type="file" multiple onChange={(e) => onChange(e.target.files, 'files')} />
          </div>
        </FormControl>

        <Button isLoading={isSubmitting} loadingText="Lähetetään" onClick={() => onSubmit()} colorScheme="blue">
          Lähetä
        </Button>
      </form>
    </div>
  );
};

export default NewTicketForm;
